import { Center, Container, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { PageRoot } from 'src/layout/PageRoot'

export const NotFound: FC<{}> = ({ ...props }) => {
  return (
    <PageRoot>
      <Container>
        <Heading textAlign="center" fontSize="lg" mt="40px">
          ページが見つかりませんでした
        </Heading>
        <Center my={6}>
          <Text maxWidth="280px">
            お探しのページはURLが間違っているか、削除された可能性があります。
          </Text>
        </Center>
      </Container>
    </PageRoot>
  )
}
